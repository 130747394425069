import {
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SEOService } from '../../services/seo.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonService } from '../../services/common.service';
import { PublicModulesFunction } from '../../function/public-modules-function';
import { OpenPopup } from '../../function/open-popup';
import { RegisterComponent } from '../register/register.component';
import { ResetPswComponent } from '../reset-psw/reset-psw.component';
import { RoleService } from '../../services/role.service';

@Component({
    selector: 'app-login',
    imports: [
        ...PublicModulesFunction,
        MatInputModule,
        MatSnackBarModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        ReactiveFormsModule,
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, OnDestroy {
  authService = inject(AuthService);
  commonService = inject(CommonService);
  seoService = inject(SEOService);
  matSnackBar = inject(MatSnackBar);
  router = inject(Router);
  hide = true;
  form!: FormGroup;

  showResendEmail: boolean = false;

  constructor(
    private ref: MatDialogRef<LoginComponent>,
    private fb: FormBuilder,
    private roleService: RoleService,
    public dialog: MatDialog,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public isDialog: boolean
  ) {}

  ngOnDestroy(): void {
    this.seoService.cleanActive();
  }

  login() {
    if (this.commonService.getCookie('_pScs')! >= 5) {
      return this.matSnackBar.open(
        'Hai superato i tentativi limiti, riprovare più tardi',
        'Chiudi',
        {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    }

    this.authService.login(this.form.value).subscribe({
      next: (response) => {
        this.ref.close('Chiusura automatica');

        this.matSnackBar.open(response.message, 'Chiudi', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        this.ref.close();
      },
      error: (error) => {
        var cookieVal = this.commonService.getCookie('_pScs');

        if (error.error.token == 'noConfirm') {
          this.showResendEmail = true;
        } else {
          this.commonService.setCookie(
            '_pScs',
            cookieVal != null ? +cookieVal + 1 : 1,
            null,
            4
          );
        }

        this.form.controls['password'].setValue('');

        this.matSnackBar.open(error.error.message, 'Chiudi', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
    });

    return false;
  }

  resetPsw() {
    OpenPopup(
      this.dialog,
      this.injector,
      true,
      'Resetta password',
      ResetPswComponent,
      'auto',
      true
    );
  }

  register() {
    OpenPopup(
      this.dialog,
      this.injector,
      null,
      'Registrati',
      RegisterComponent,
      '100%',
      true
    );

    this.closepopup();
  }

  ResendEmail() {
    var email = this.form.controls['email'].value;

    if (email === null || email == '') {
      this.form.setErrors({ resetPsw: true });
    } else {
      if (this.form.controls['email'].valid) {
        this.authService.resendConfirmEmail(email).subscribe((res) => {
          this.matSnackBar.open(res.message, 'Chiudi', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.showResendEmail = false;
        });
      }
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      rememberMe: [false],
    });
  }

  closepopup() {
    this.ref.close('Chiusura automatica');
  }
}
